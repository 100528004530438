<template>
  <div
    class="ui-modal__wrapper"
    :class="{open: props.modelValue}"
    @mousedown="closeModal"
  >
    <div
      v-if="props.modelValue"
      class="ui-modal"
      @mousedown.stop
    >
      <slot />
    </div>
  </div>
</template>

<script lang="ts" setup>
const emits = defineEmits(['update:modelValue'])
const positionY = ref<number>(0);
const props = defineProps<{
  modelValue: boolean,
}>()
function closeModal () {
  emits('update:modelValue', false)
}

watch(() => props.modelValue, (value)=> {
  if (value) {
    positionY.value = window.pageYOffset;
    document.body.style.position = 'fixed';
    document.body.style.height = '100%';
    document.body.style.top = `-${positionY.value}px`;
  } else if (value === false) {
    document.body.style.removeProperty('position');
    document.body.style.removeProperty('top');
    document.body.style.removeProperty('height');
    window.scroll(0, positionY.value || 0);
    positionY.value = 0;
  }
})
</script>

<style lang="scss" scoped>
.ui-modal {
  max-height: calc(100vh - 48px);
  max-width: calc(100vw - 48px);
  border-radius: 4px;
  box-shadow: 0 2px 4px -1px #0003, 0 4px 5px #00000024, 0 1px 10px #0000001f;
  overflow: auto;
  pointer-events: all;
  will-change: scroll-position;

  &__wrapper {
    height: 100%;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10000;
    background: rgba(3, 8, 13, 0.8);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    pointer-events: none;
    opacity: 0;
    visibility: hidden;
    transition: all 0.2s ease-in-out;
    &.open {
      pointer-events: all;
      opacity: 1;
      visibility: visible;
    }
  }

  @media (max-width: $md) {
    max-height: 90%;
    max-width: 90%;
    width: 85vw;
  }
}
</style>
